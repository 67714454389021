<template>
    <div class="web-app-tab-bar">
        <mobile-download-banner v-if="!showIntro" class="d-block d-lg-none" />

        <div class="tab-bar">
            <div class="links">
                <router-link
                    v-for="(navigation, index) in mobileNavigationLinks"
                    :key="index"
                    v-intro-autostart="showIntro"
                    v-intro-autostart.config="{ showStepNumbers: false, prevLabel: 'Previous', nextLabel: 'Next', hidePrev: true, hideNext: true, tooltipPosition: 'auto' }"
                    v-intro-autostart:on.complete="onIntroCompleted"
                    v-intro-autostart:on.exit="onExit"
                    v-intro="navigation.introText"
                    v-intro-position="'auto'"
                    v-intro-tooltip-class="`mobile step-${index + 1}`"
                    class="link"
                    :to="{ name: navigation.routeName, params: navigation.routeParams }"
                >
                    <div class="icon">
                        <img :src="navigation.icon" :alt="navigation.name">
                        <template v-if="navigation.name === 'Inbox' && userNotification !== 0">
                            <span class="notification-badge badge badge-danger">{{ new_notification || userNotification }}</span>
                        </template>
                    </div>
                    <span>{{ navigation.name }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "WebAppTabBar",
    components: {
        MobileDownloadBanner: () => import(/* webpackChunkName: "mobile-download-banner" */ "@c/molecules/mobile-download-banner")
    },
    props: {
        showIntro: {
            type: Boolean,
            default: false
        },
        navigationLinks: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            mobileNavigationLinks: []
        };
    },
    computed: {
        ...mapState({
            userData: state => state.User.data
        }),
        userNotification() {
            // eslint-disable-next-line camelcase
            const { new_notification } = this.userData;

            // eslint-disable-next-line camelcase
            return new_notification ? new_notification : 0;
        }
    },
    created() {
        this.setMobileNavigationLinks();
    },
    methods: {
        setMobileNavigationLinks() {
            const searchNavigationLink = {
                name: "Search",
                icon: require("@assets/img/icons/app/search.svg"),
                routeName: "web-app-search",
                introText: "<h1>Search</h1><p>Find Memos you’ll love by searching for keywords, topics, and creators</p>",
                routeParams: { searchString: false }
            };

            const mobileNavigationLinks = [...this.navigationLinks];
            const homeNavigationLinkIndex = mobileNavigationLinks.findIndex(navigationLink => navigationLink.routeName == "web-app-home");
            mobileNavigationLinks.splice(homeNavigationLinkIndex + 1, 0, searchNavigationLink);
            this.mobileNavigationLinks = mobileNavigationLinks;
        },
        onIntroCompleted() {
            this.$emit("on-intro-completed");
        },
        onExit() {
            this.$emit("on-exit");
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-tab-bar {
    position: fixed;
    background-color: black;
    bottom: 0;
    z-index: 11;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15.62%, rgba(0, 0, 0, 0.8) 100%);

    .tab-bar {
        padding: 10px 30px;
        background-color: #3A3A3C;
        border-radius: 100px;

        .links {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .link {
                margin-left: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: white;
                text-decoration: none;
                position: relative;

                .notification-badge {
                    background: #05A88F;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;

                    position: absolute;
                    top: -5px;
                    right: -3px;
                }

                .icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1px;
                }

                &:first-child {
                    margin-left: 0;
                }

                &.router-link-active {
                    color: $primary-color;
                }
            }
        }
    }

    &.introjs-fixParent {
        /* position: absolute; */
        z-index: 9999999 !important;
    }
}
</style>
